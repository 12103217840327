import React from 'react';
import Link from 'components/ui/link';
import Article, { SubTitle } from 'components/help/helpArticle';

const Content = () => {
  return (
    <div>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        Personal data protection and privacy are the core values of LiveSession. Below we’ve
        described all you need to know about <strong>GDPR</strong>. If you don’t have time to read
        through complex legal documents, this short summary should be enough.
      </div>
      <SubTitle>What is GDPR?</SubTitle>
      <p>
        It’s a law related to processing personal data of citizens of the European Union (EU) and
        the European Economic Area (EEA).
      </p>

      <SubTitle style={{ marginTop: '3rem' }}>Why is LiveSession bound by the GDPR?</SubTitle>
      <p>
        Our services are available to customers from all over the world, including the EU and the
        EEA.
      </p>
      <p>
        Our tool records the user’s activity on a website or in an app. This recording is called{' '}
        <strong>session replay</strong>.
      </p>

      <SubTitle style={{ marginTop: '3rem' }}>
        What is a session replay? How is it related to personal data?
      </SubTitle>
      <p>
        A <strong>session replay is a reconstruction of the user’s journey on a website</strong> or
        within a web application. It consists of mouse and keyboard movements, scrolls, taps, and
        clicks. Watching a session replay feels like sitting next to the user and watching them
        interact with your website.
      </p>
      <p>
        When you use LiveSession on your website, you allow the service to process user data. There
        are two main ways of processing data, <strong>passive</strong> and <strong>active</strong>.
      </p>
      <p>
        <strong>Passive processing</strong> is related to session replays stored on Google Cloud. As
        our client, you’re the only person who can access this data.{' '}
      </p>
      <p>
        <strong>Active processing</strong> involves the data you send us intentionally, such as
        company details, email addresses, and the names of your employees. You need to share this
        information to use our service.
      </p>

      <SubTitle style={{ marginTop: '3rem' }}>
        Is it possible to use LiveSession without recording any personal data?
      </SubTitle>
      <p>
        <strong>Yes, it’s possible</strong>. Here’s how it works:
      </p>
      <p>
        Passively processed data is <strong>anonymous by default</strong>. This applies to forms on
        your website, too. If you’d like to identify recorded users, you can do it with our{' '}
        <Link href="/help/user-identification-custom-properties/">custom properties feature</Link>.
        By default, we do not record any inputs that can be potentially sensitive.{' '}
      </p>
      <p>
        On the other hand, if personal data is included in the <strong>static content</strong> of
        your website,{' '}
        <Link href="/help/how-to-anonymize-sensitive-page-content/">
          <strong>you need to anonymize it yourself</strong>
        </Link>
        . Otherwise, it will be recorded.{' '}
      </p>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <strong>Important tip:</strong> We suggest that you <strong>audit your website</strong>.
        Make sure that you exclude all fields that could contain sensitive information. This is a
        way to make sure that your website is GDPR-compliant.
      </div>
      <p>
        As the website owner, you decide what kind of information (and for what purposes) you’re
        going to process. Because of that, you might need to get consent from your users. It’s good
        to regulate these terms in your privacy policy and include link to our privacy policy, too.
      </p>
      <p>
        LiveSession provides the session recording tool and protects your privacy. We’re also here
        to help you exercise rights related to personal data.
      </p>

      <SubTitle style={{ marginTop: '3rem' }}>What are the user rights provided by GDPR?</SubTitle>
      <p>Here are some of the user rights under the GDPR that you need to consider: </p>
      <ul>
        <li>
          <strong>The right to amend, access and receive copies of personal data.</strong>
          <p>
            The user can ask you to send a copy of their personal data. You can download a file with
            this data from your account.
          </p>
        </li>
        <li>
          <strong>The right to erase data.</strong>
          <p>
            The user can ask you to delete their personal data. It’s also known as the right to be
            forgotten. You can erase the user’s data very easily.
          </p>
        </li>
        <li>
          <strong>Limitations on data processing.</strong>
          <p />
        </li>
        <li>
          <strong>The right to object to the processing of personal data.</strong>
          <p />
        </li>
        <li>
          <strong>The right to withdraw consent for processing data</strong>
          <p>The user can ask you to limit the processing of their data.</p>
          <p>
            You can decide what kind of data you are recording. Passively obtained data is always
            anonymous by default.{' '}
            <Link href="/help/how-to-anonymize-sensitive-page-content/">
              You can anonymize other kinds of data manually.{' '}
            </Link>
          </p>
          <p>
            What’s more, the user can also object to processing their data at any time without
            providing a reason. This does not affect the legality of the previous processing.
          </p>
        </li>
        <li>
          <strong>The right to data portability</strong>
          <p>If requested, it’s possible to move the user’s personal data to another place.</p>
        </li>
      </ul>
      <p>
        To exercise these rights, please get in touch with LiveSession at:{' '}
        <a href="mailto:privacy@livesession.io">hello@livesession.io</a>
      </p>
      <p>
        The GDPR requires us to answer within <strong>30 days</strong>. We’ll do our best to reply
        as soon as possible.{' '}
      </p>

      <SubTitle style={{ marginTop: '3rem' }}>For more details, read our:</SubTitle>
      <ul>
        <li>
          <Link href="/privacy-policy/">Privacy Policy</Link>
        </li>
        <li>
          <Link href="/terms-of-service/">Terms of Service</Link>
        </li>
        <li>
          <Link href="/data-processing-agreement/">Data Proccessing Agreement</Link>
        </li>

        <li>
          <Link href="/data-security/">Data security information</Link>
        </li>
        <li>
          <Link href="/subprocessors/">List of subprocessors</Link>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cloud.google.com/security/compliance/"
          >
            Google standards and certificates
          </a>
        </li>
      </ul>
    </div>
  );
};

const related = [
  {
    link: '/help/ccpa/',
    title: 'CCPA compliance',
    description:
      'Personal data protection and privacy are the core values of LiveSession. We’ve described everything you need to know about the CCPA - California Consumer Privacy Act.',
  },
];

export const frontmatter = {
  metaTitle: 'GDPR compliance',
  metaDescription: `Personal data protection and privacy are the core values of LiveSession. Here's described all you need to know about GDPR.`,
  canonical: '/help/gdpr/',
};

const Wrapped = Article(Content);
export default () => (
  <Wrapped related={related} section="Privacy" title={frontmatter.metaTitle} {...frontmatter} />
);
